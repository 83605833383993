<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-33">
            <FormDate
              v-model="filters.from"
              name="date"
              label="Date From"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <FormDate
              v-model="filters.to"
              name="date"
              label="Date To"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <MoneyflowCategoriesAutocomplete
              v-model="filters.category_id"
              label="Category"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="showModal = true"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Entry</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @changed="getMoneyOutflow"
              @onDownload="onDownload"
              @onDelete="onDelete"
              @onShowDetails="onShowDetails"
              @onPreview="onPreview"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <moneyflow-form
      v-if="showModal"
      :item="flowDetails"
      @close="onCloseModal"
      @onSave="onSave"
      @onDownload="onDownload"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import { MoneyflowCategoriesAutocomplete } from '@/components/Inputs/selects';
import downloadFile from '@/utils/downloadFile';
import MoneyflowForm from './MoneyflowForm.vue';

export default {
  components: {
    VueTable,
    FormDate,
    MoneyflowForm,
    MoneyflowCategoriesAutocomplete,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'moneyflow_id',
            mask: 'Expenditure ID',
            sortable: true,
          },
          {
            title: 'date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'category',
            mask: 'category',
            callback: (x) => (x.parent ? `${x.parent.name} / ${x.name}` : x.name),
          },
          {
            title: 'department.name',
            mask: 'department',
            sortable: true,
          },
          {
            title: 'total',
            pre: '€',
            sortable: true,
          },
          {
            title: 'remark',
            max_chars: 30,
          },
          {
            title: 'updater.name',
            mask: 'updated by',
          },
          {
            title: 'comment',
            mask: 'comment',
            max_chars: 30,
          },
          {
            title: 'payment_method.name',
            mask: 'payment method',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Preview Proof of Payment',
            callback: 'onPreview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Download Proof of Payment',
            callback: 'onDownload',
            icon: 'get_app',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Payment',
            callback: 'onDelete',
            icon: 'delete',
          },
        ],
        values: {},
      },
      filters: {
        from: null,
        to: null,
        category_id: null,
      },
      showModal: false,
      flowDetails: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getMoneyOutflow(params) {
      this.request(
        this.$API.MONEY_OUTFLOW,
        'get',
        {
          ...params,
          ...this.filters,
        },
        ({ data }) => {
          const aux = data;
          aux.data = aux.data.map((x) => {
            const y = x;
            y.onPreview = x.file !== null;
            y.onDownload = x.file !== null;
            return y;
          });
          this.vTable.values = data;
        },
      );
    },
    onFilter() {
      this.$refs.vtable.init();
    },
    onSave() {
      this.$refs.vtable.init();
    },
    onShowDetails(item) {
      this.flowDetails = { ...item };
      this.showModal = true;
    },
    onPreview(item) {
      this.openFileUrl(item.file.file_id);
    },
    onDownload(item) {
      this.request(
        `${this.$API.MONEYFLOW}download/${item.moneyflow_id}`,
        'download',
        null,
        ({ data }) => {
          const filename = item.file.path.split('/')[
            item.file.path.split('/').length - 1
          ];
          downloadFile(data, filename);
        },
      );
    },
    onDelete(item) {
      this.fireModal({
        title: 'Deleting Expenditure!',
        html: 'Are you sure you want to remove this expediture?',
        input: 'textarea',
        inputPlaceholder:
          'Please explain why you want to delete this expediture.',
      }).then((data) => {
        if (data.value) {
          this.request(
            `${this.$API.MONEYFLOW}/${item.moneyflow_id}`,
            'delete',
            {
              remark: data.value,
            },
            () => {
              this.fireSuccess('Expenditure Deleted successfully');
              this.onSave();
            },
          );
        }
      });
    },
    onCloseModal() {
      this.showModal = false;
      this.flowDetails = null;
    },
  },
};
</script>

<style scoped></style>
